export const SocketProtocol = 10;
export const PushProtocol = 1;
export const MaxSpacesPerUser = 100;
export const MaxSpacesOwnedPerUser = 20;
export const MaxMembersPerSpace = 5000;
export const MaxRolesPerSpace = 250;
export const MaxInvitesPerSpace = 500;
export const MaxChannelsPerSpace = 500;
export const MaxOverridesPerChannel = 250;
export const MaxEmojisPerSpace = 50;
export const MaxMembersPerChannel = 50;

export enum ChannelType {
  DM = 0,
  Group = 1,
  SpaceCategory = 2,
  SpaceText = 3,
  SpaceVoice = 4,
}

export enum MessageType {
  PrivateText = 0,
  PrivateUpload = 11,
  PrivateUploadOld = 1,
  FriendAccept = 2,
  GroupCreate = 3,
  GroupAdd = 4,
  GroupRemove = 5,
  GroupName = 6,
  GroupAvatar = 7,
  GroupLeave = 8,
  SpaceText = 9,
  SpaceUpload = 10,
}

export enum SocketMessageType {
  CStart = 0,
  CChannelTyping = 1,
  CSetAway = 9,
  CSetPushInfo = 10,
  SReady = 12,
  SSelfUpdate = 13,
  SFriendCreate = 17,
  SFriendUpdate = 18,
  SFriendDelete = 19,
  SChannelCreate = 20,
  SChannelUpdate = 21,
  SChannelDelete = 22,
  SChannelMemberCreate = 23,
  SChannelMemberUpdate = 24,
  SChannelMemberDelete = 34,
  SMessageCreate = 26,
  SMessageDelete = 27,
  SMessageUpdate = 29,
  SSpaceCreate = 35,
  SSpaceUpdate = 36,
  SSpaceDelete = 37,
  SSpaceMemberCreate = 41,
  SSpaceMemberUpdate = 42,
  SSpaceMemberDelete = 43,
  SSpaceRoleCreate = 44,
  SSpaceRoleUpdate = 45,
  SSpaceRoleDelete = 46,
  SUserUpdate = 48,
  STypingStateUpdate = 50,
  SVoiceStateUpdate = 51,
  SChannelStateUpdate = 52,
  CCallJoin = 53,
  CCallLeave = 54,
  SCallReset = 55,
  CCallUpdateFlags = 61,
  CCallUpdateKeys = 62,
  SCallUpdateKeys = 63,
  CCallUpdateKeysACK = 64,
  SCallUpdateKeysACK = 65,
  CCallUpdateStreams = 66,
  SCallOffer = 67,
  CCallAnswer = 68,
  SCallICECandidate = 69,
  CCallICECandidate = 70,
  SError = 71,
  SUserStatusUpdate = 72,
  CCallRequestRestartICE = 73,
  CDisconnect = 74,
  SDisconnect = 11,
  SReset = 75,
  CResume = 76,
  SResumeOK = 77,
  SSpaceEmojiCreate = 78,
  SSpaceEmojiUpdate = 79,
  SSpaceEmojiDelete = 80,
  SDisabled = 81,
}

export enum ColorMode {
  Light = 0,
  Dark = 1,
  DarkOLED = 2,
  DarkMocha = 3,
}

export enum ColorTheme {
  Rosewater = 0,
  Flamingo = 1,
  Pink = 2,
  Mauve = 3,
  Red = 4,
  Maroon = 5,
  Peach = 6,
  Yellow = 7,
  Green = 8,
  Teal = 9,
  Sky = 10,
  Sapphire = 11,
  Blue = 12,
  Lavender = 13,
}

export enum Status {
  Online = 0,
  Away = 1,
  Busy = 2,
  Offline = 3,
}

export enum CallStreamType {
  Audio = 0,
  Video = 1,
  DisplayVideo = 2,
  DisplayAudio = 3,
}

export enum NotificationMode {
  All = 0,
  MentionsOnly = 1,
  None = 2,
  Default = 3,
}

export enum SpacePermission {
  // meta:
  Admin = 1 << 0,
  // general:
  ViewChannels = 1 << 2,
  ManageChannels = 1 << 3,
  ManageRoles = 1 << 4,
  ManageSpace = 1 << 5,
  ManageEmojis = 1 << 25,
  // members:
  CreateInvite = 1 << 6,
  SetAlias = 1 << 7,
  ManageAliases = 1 << 8,
  KickMember = 1 << 9,
  BanMember = 1 << 10,
  // text channels:
  CreateMessage = 1 << 11,
  CreateMessageLink = 1 << 12,
  CreateMessageUpload = 1 << 13,
  MentionAny = 1 << 14,
  ManageMessages = 1 << 15,
  GetMessages = 1 << 16,
  // voice channels:
  VoiceConnect = 1 << 17,
  VoiceUseAudio = 1 << 18,
  VoiceUseAudioOpen = 1 << 19,
  VoiceUseVideo = 1 << 20,
  VoiceUseDisplay = 1 << 21,
  VoiceMuteMembers = 1 << 22,
  VoiceDeafenMembers = 1 << 23,
  VoiceMoveMembers = 1 << 24,
}

export enum SpaceChannelOverrideType {
  Everyone = 0,
  Role = 1,
  Member = 2,
}

export enum UserFlag {
  System = 1 << 0,
  Staff = 1 << 1,
  Tester = 1 << 2,
  EarlySupporter = 1 << 3,
  BugReporter = 1 << 4,
  Bot = 1 << 5,
}

export enum VoiceStateFlags {
  Muted = 1 << 0,
  Deaf = 1 << 1,
  VideoEnabled = 1 << 2,
  DisplayEnabled = 1 << 3,
  SpaceMuted = 1 << 4, // TODO: implement space muting/deafening.
  SpaceDeaf = 1 << 5,
}
